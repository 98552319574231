import Link from "next/link";
import ArrowLink from "./ArrowLink";
import Image from "next/future/image";
import ButtonLink from "./ButtonLink";
import styles from "../styles/CaseStudiesCarousel.module.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
export default function CaseStudyCard({
  caseStudy,
  isCarousel = false,
  css = {},
}) {
  const { width } = useWindowDimensions();
  const isMountainFilm = caseStudy.slug === "mountainfilm";
  if (caseStudy.featured && !isCarousel) {
    return (
      <Link href="/case-studies/[slug]" as={`/case-studies/${caseStudy.slug}`}>
        <a
          className={`${
            isCarousel
              ? `${styles.embla__slide_featured} px-8`
              : "md:col-span-2 flex w-full relative"
          }`}
        >
          {width && (
            <Image
              priority
              src={
                width > 1024
                  ? caseStudy.hero.featuredImage
                  : caseStudy.hero.mobileImage
              }
              alt={`${caseStudy.title} thumbnail}`}
              className="rounded-lg object-cover object-bottom w-full h-full"
              placeholder="blur"
              blurDataURL={caseStudy.hero.featuredImage}
              fill
            />
          )}

          <div
            className={`relative flex flex-col justify-center gap-4 xl:gap-6 text-white lg:pb-48 px-6 py-8 xl:pt-24 ${
              isMountainFilm
                ? "px-6 py-8 pb-cardBottom md:py-16 md:pb-32 md:px-16 lg:pb-36 lg:px-20 lg:pt-20 xl:pt-24 xl:px-24 xl:pb-cardBottom"
                : "lg:pb-48 px-6 py-8 xl:pt-24"
            }`}
          >
            <div className="text-3xl leading-tight md:text-4xl lg:text-5xl xl:text-6xl font-extrabold lg:leading-none max-w-prose sm:max-w-featuredCardWidth xl:max-w-featuredCardWidthFixed">
              {caseStudy.title}
            </div>
            {caseStudy.subtitleLong && (
              <div className="text-base leading-6 lg:text-xl font-bold lg:leading-7 max-w-prose sm:max-w-featuredCardWidth xl:max-w-featuredCardWidthFixed">
                {caseStudy.subtitleLong}
              </div>
            )}
            <ButtonLink
              extraClasses="px-14 md:px-12"
              color="white"
              linkText={caseStudy.indexLinkText}
            />
          </div>
        </a>
      </Link>
    );
  }
  if (caseStudy.featured && isCarousel) {
    return (
      <Link href="/case-studies/[slug]" as={`/case-studies/${caseStudy.slug}`}>
        <a
          className={`${
            isCarousel
              ? `${styles.embla__slide_featured} px-8`
              : "md:col-span-2 flex w-full relative"
          }`}
        >
          {width && (
            <Image
              priority
              src={
                width > 1024
                  ? caseStudy.hero.featuredImage
                  : caseStudy.hero.mobileImage
              }
              alt={`${caseStudy.title} thumbnail}`}
              className="rounded-lg object-cover object-bottom w-full h-full"
              placeholder="blur"
              blurDataURL={caseStudy.hero.featuredImage}
              fill
            />
          )}

          <div
            className={`relative flex flex-col justify-center gap-4 xl:gap-6 text-white px-6 py-8 pb-cardBottom lg:py-16 lg:pb-cardBottom lg:px-16 xl:pb-cardBottom xl:px-20 xl:pt-20`}
          >
            <div className="text-3xl leading-tight md:text-4xl lg:text-5xl xl:text-6xl font-extrabold lg:leading-none max-w-prose lg:max-w-featuredCardWidth xl:max-w-featuredCardWidthFixed">
              {caseStudy.title}
            </div>
            {caseStudy.subtitleLong && (
              <div className="text-base leading-6 lg:text-xl font-bold lg:leading-7 max-w-prose lg:max-w-featuredCardWidth xl:max-w-featuredCardWidthFixed">
                {caseStudy.subtitleLong}
              </div>
            )}
            <ButtonLink
              extraClasses="px-10 xl:px-12"
              color="white"
              linkText={caseStudy.indexLinkText}
            />
          </div>
        </a>
      </Link>
    );
  }
  if (!caseStudy.featured && !isCarousel) {
    return (
      <Link href="/case-studies/[slug]" as={`/case-studies/${caseStudy.slug}`}>
        <a className="flex w-full">
          <div className="flex flex-col flex-1 items-stretch">
            <div className="relative w-full">
              <Image
                src={caseStudy.thumbnail}
                alt={`${caseStudy.title} thumbnail`}
                className="rounded-lg object-cover min-w-full"
                width={592}
                height={393}
                placeholder="blur"
                blurDataURL={caseStudy.thumbnail}
                sizes="100vw"
              />
            </div>
            <div className="pt-4 lg:pt-6 leading-7 pb-2 text-xl lg:text-4xl font-extrabold lg:leading-10 text-dark">
              {caseStudy.title}
            </div>
            {caseStudy.subtitle && (
              <div className="text-base lg:text-xl font-normal lg:leading-7 text-gray-link pb-2">
                {caseStudy.subtitleShort}
              </div>
            )}
            <ArrowLink color="red" linkText={caseStudy.indexLinkText} />
          </div>
        </a>
      </Link>
    );
  }
  if (!caseStudy.featured && isCarousel) {
    return (
      <Link
        href="/case-studies/[slug]"
        as={`/case-studies/${caseStudy.slug}`}
        className={`${styles.embla__slide}`}
      >
        <a className={`${styles.embla__slide}`}>
          <div className="relative flex flex-col items-center h-full lg:grid lg:grid-cols-2 lg:gap-10">
            <div>
              <div style={css}>
                <Image
                  className="rounded-lg object-cover"
                  src={caseStudy.thumbnail}
                  width={596}
                  height={397}
                  alt={`${caseStudy.title} thumbnail`}
                  placeholder="blur"
                  blurDataURL={caseStudy.thumbnail}
                  priority
                />
              </div>
            </div>
            <div className="flex flex-col justify-center gap-2 w-full">
              <span className="text-xl lg:text-4xl font-extrabold leading-7 lg:leading-10 mt-4 lg:mt-0">
                {caseStudy.title}
              </span>
              <p className="text-base lg:text-xl font-normal leading-6 lg:leading-7 text-gray-link">
                {caseStudy.subtitleShort}
              </p>
              <ArrowLink color="red" linkText={caseStudy.linkText} />
            </div>
          </div>
        </a>
      </Link>
    );
  }
}
